exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-content-js": () => import("./../../../src/pages/content.js" /* webpackChunkName: "component---src-pages-content-js" */),
  "component---src-pages-drones-js": () => import("./../../../src/pages/drones.js" /* webpackChunkName: "component---src-pages-drones-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-webdesign-js": () => import("./../../../src/pages/webdesign.js" /* webpackChunkName: "component---src-pages-webdesign-js" */)
}

